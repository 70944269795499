import React from 'react'
import { Routes, Route } from 'react-router-dom'
import Footer from './Footer'
import Header from './Header'
import Home from './pages/Home'
import OneNumberLotto from './pages/OneDigiLotto'
import ThreeNumberLotto from './pages/ThreeDigiLotto'
import Defi from './pages/Defi'
import About from './pages/About'
import Winners from './pages/Winners'
import Faq from './pages/Faq'
import DefiApp from './pages/DefiApp'

function App() {
  return (
    <div>
    <Header /><Routes>
      <Route index element={<Home />}>
      </Route>
      <Route path="/oneDigiLotto" element={<OneNumberLotto />}>
      </Route>
      <Route path="/threeDigiLotto" element={<ThreeNumberLotto />}>
      </Route>
      <Route path="/defi" element={<Defi />}>
      </Route>
      <Route path="/defiApp" element={<DefiApp />}>
      </Route>
      <Route path="/about" element={<About />}>
     </Route>
      <Route path="/winners" element={<Winners />}>
      </Route>
      <Route path="/faq" element={<Faq />}>
      </Route>
    </Routes><Footer /></div>
  )
}

export default App;
