import React from 'react'

function Winners() {
  return (
    <div>
      <section id="banner-section" class="inner-banner games">
        <div class="banner-content d-flex align-items-center">
            <div class="container">
                <img src="images/games-banner-right.png" class="banner-side" alt= ""/>
                <div class="row d-flex justify-content-start">
                    <div class="col-lg-12">
                        <div class="text-area">
                            <h1>Winners</h1>
                            <div class="breadcrumb-area">
                                <nav aria-label="breadcrumb">
                                    <ol class="breadcrumb d-flex">
                                        <li class="breadcrumb-item"><a href="index.html">Home</a></li>
                                        <li class="breadcrumb-item active" aria-current="page">Winners</li>
                                    </ol>
                                </nav>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>

    <section id="available-game-section" class="index-2 games winners">
        <div class="overlay pt-120 pb-120">
            <div class="container wow fadeInUp">
                <div class="row justify-content-center">
                    <div class="col-lg-6 col-md-9 col-sm-8">
                        <div class="section-header text-center">
                            <h2 class="title">All <span>Winners</span></h2>
                            <p>To meet our top winners</p>
                        </div>
                    </div>
                </div>
                <div class="menu-bar">
                    <div class="row justify-content-between">
                        <div class="col-lg-5 col-md-12">
                            <form action="#">
                                <div class="input-area d-flex">
                                    <div class="icon d-flex align-items-center"><img src="images/search-icon.png"
                                            alt= ""/></div>
                                    <input type="text" placeholder="What’s your game?"></input>
                                </div>
                            </form>
                        </div>
                        <div class="col-lg-7 col-md-12 d-flex justify-content-center justify-content-lg-end">
                            <ul class="nav nav-tabs" id="myTab" role="tablist">
                                <li class="nav-item">
                                    <a class="cmn-btn-alt cmn-btn active" id="featured-tab" data-toggle="tab"
                                        href="#featured" role="tab" aria-controls="featured" aria-selected="true"> Todays</a>
                                </li>
                                <li class="nav-item">
                                    <a class="cmn-btn-alt cmn-btn" id="instant-tab" data-toggle="tab" href="#instant"
                                        role="tab" aria-controls="instant" aria-selected="false"> Weekend</a>
                                </li>
                                <li class="nav-item">
                                    <a class="cmn-btn-alt cmn-btn" id="tournaments-tab" data-toggle="tab"
                                        href="#tournaments" role="tab" aria-controls="tournaments"
                                        aria-selected="false"> Monthly</a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div class="tab-content" id="myTabContentt">
                    <div class="tab-pane fade show active" id="featured" role="tabpanel" aria-labelledby="featured-tab">
                        <div class="row contained-area mb-30-none">
                            <div class="col-lg-3 col-md-4 col-sm-4">
                                    <img src="images/winner-1.png" alt= ""/>
                                    <span>$500</span>
                            </div>
                            <div class="col-lg-3 col-md-4 col-sm-4">
                                    <img src="images/winner-2.png" alt= ""/>
                                    <span>$500</span>
                            </div>
                            <div class="col-lg-3 col-md-4 col-sm-4">
                                    <img src="images/winner-3.png" alt= ""/>
                                    <span>$400</span>
                            </div>
                            <div class="col-lg-3 col-md-4 col-sm-4">
                                    <img src="images/winner-4.png" alt= ""/>
                                    <span>$400</span>
                            </div>
                            <div class="col-lg-3 col-md-4 col-sm-4">
                                    <img src="images/winner-5.png" alt= ""/>
                                    <span>$300</span>
                            </div>
                            <div class="col-lg-3 col-md-4 col-sm-4">
                                    <img src="images/winner-6.png" alt= ""/>
                                    <span>$300</span>
                            </div>
                            <div class="col-lg-3 col-md-4 col-sm-4">
                                    <img src="images/winner-7.png" alt= ""/>
                                    <span>$200</span>
                            </div>
                            <div class="col-lg-3 col-md-4 col-sm-4">
                                    <img src="images/winner-8.png" alt= ""/>
                                    <span>$200</span>
                            </div>
                        </div>
                    </div>
                    <div class="tab-pane fade" id="instant" role="tabpanel" aria-labelledby="instant-tab">
                        <div class="row contained-area mb-30-none">
                            <div class="col-lg-3 col-md-4 col-sm-4">
                                    <img src="images/winner-3.png" alt= ""/>
                                    <span>$500</span>
                            </div>
                            <div class="col-lg-3 col-md-4 col-sm-4">
                                    <img src="images/winner-5.png" alt= ""/>
                                    <span>$500</span>
                            </div>
                            <div class="col-lg-3 col-md-4 col-sm-4">
                                    <img src="images/winner-7.png" alt= ""/>
                                    <span>$400</span>
                            </div>
                            <div class="col-lg-3 col-md-4 col-sm-4">
                                    <img src="images/winner-8.png" alt= ""/>
                                    <span>$400</span>
                            </div>
                            <div class="col-lg-3 col-md-4 col-sm-4">
                                    <img src="images/winner-1.png" alt= ""/>
                                    <span>$300</span>
                            </div>
                            <div class="col-lg-3 col-md-4 col-sm-4">
                                    <img src="images/winner-4.png" alt= ""/>
                                    <span>$300</span>
                            </div>
                            <div class="col-lg-3 col-md-4 col-sm-4">
                                    <img src="images/winner-6.png" alt= ""/>
                                    <span>$200</span>
                            </div>
                            <div class="col-lg-3 col-md-4 col-sm-4">
                                    <img src="images/winner-2.png" alt= ""/>
                                    <span>$200</span>
                            </div>
                        </div>
                    </div>
                    <div class="tab-pane fade" id="tournaments" role="tabpanel" aria-labelledby="tournaments-tab">
                        <div class="row contained-area mb-30-none">
                            <div class="col-lg-3 col-md-4 col-sm-4">
                                    <img src="images/winner-4.png" alt= ""/>
                                    <span>$500</span>
                            </div>
                            <div class="col-lg-3 col-md-4 col-sm-4">
                                    <img src="images/winner-3.png" alt= ""/>
                                    <span>$500</span>
                            </div>
                            <div class="col-lg-3 col-md-4 col-sm-4">
                                    <img src="images/winner-5.png" alt= ""/>
                                    <span>$400</span>
                            </div>
                            <div class="col-lg-3 col-md-4 col-sm-4">
                                    <img src="images/winner-1.png" alt= ""/>
                                    <span>$400</span>
                            </div>
                            <div class="col-lg-3 col-md-4 col-sm-4">
                                    <img src="images/winner-2.png" alt= ""/>
                                    <span>$300</span>
                            </div>
                            <div class="col-lg-3 col-md-4 col-sm-4">
                                    <img src="images/winner-8.png" alt= ""/>
                                    <span>$300</span>
                            </div>
                            <div class="col-lg-3 col-md-4 col-sm-4">
                                    <img src="images/winner-6.png" alt= ""/>
                                    <span>$200</span>
                            </div>
                            <div class="col-lg-3 col-md-4 col-sm-4">
                                   <img src="images/winner-7.png" alt= ""/>
                                    <span>$200</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>

    <section id="todays-winner-section" class="games">
        <div class="overlay pt-120 pb-120">
            <div class="container wow fadeInUp">
                <div class="row justify-content-center">
                    <div class="col-lg-8 col-md-10">
                        <div class="section-header text-center">
                            <h2 class="title">Recent Big <span>Winners</span></h2>
                            <p>We update our site regularly; more and more winners are added every day! To locate the
                                most recent winner's information</p>
                        </div>
                    </div>
                </div>
                <div class="row d-flex justify-content-center">
                    <div class="col-lg-5 col-md-4">
                        <div class="left-side text-center">
                            <h5 class="head">Jackpot Winner</h5>
                            <div class="mid-area">
                                <img src="images/tropy-2.png" alt= ""/>
                            </div>
                            <div class="profile">
                                <img src="images/user-4.png" alt= ""/>
                                <h5>€ 496</h5>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-7 col-md-8">
                        <div class="right-side">
                            <div class="title-area">
                                <img src="images/ribbon.png" alt= ""/>
                                <h5>Recent Wins</h5>
                            </div>
                            <div class="winner-chart">
                                <div class="single-item d-flex justify-content-between align-items-center">
                                    <div class="left-area d-flex align-items-center">
                                        <img src="images/win-1.png" class="pos" alt= ""/>
                                            <img src="images/user-1.png" alt= ""/>
                                            <h6>Bessie Cooper</h6>
                                    </div>
                                    <div class="win-price">
                                        <span>+</span>
                                        <span>56.65994</span>
                                        <span class="cur">ETH</span>
                                        <span><img src="images/double-right-alt.png" alt= ""/></span>
                                    </div>
                                </div>
                                <div class="single-item d-flex justify-content-between align-items-center">
                                    <div class="left-area d-flex align-items-center">
                                        <img src="images/win-2.png" class="pos" alt= ""/>
                                            <img src="images/user-2.png" alt= ""/>
                                            <h6>Jerome Bell</h6>
                                    </div>
                                    <div class="win-price">
                                        <span>+</span>
                                        <span>56.65994</span>
                                        <span class="cur">ETH</span>
                                        <span><img src="images/double-right-alt.png" alt= ""/></span>
                                    </div>
                                </div>
                                <div class="single-item d-flex justify-content-between align-items-center">
                                    <div class="left-area d-flex align-items-center">
                                        <img src="images/win-3.png" class="pos" alt= ""/>
                                            <img src="images/user-3.png" alt= ""/>
                                            <h6>Darrell Steward</h6>
                                    </div>
                                    <div class="win-price">
                                        <span>+</span>
                                        <span>56.65994</span>
                                        <span class="cur">ETH</span>
                                        <span><img src="images/double-right-alt.png" alt= ""/></span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    </div>
  )
}

export default Winners