import React from 'react'
import { Link } from 'react-router-dom'


function Home() {
  return(
    <div>
      <div id="banner-section">
        <div className="banner-content d-flex align-items-center">
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-lg-10">
                        <div className="main-content">
                            <div className="top-area justify-content-center text-center">
                                <h1>Play & Win <br/> <span>Prizes</span></h1>
                                <p>Fun & Fair Prizes Every Game</p>
                                <button type="button" className="cmn-btn" data-toggle="modal"
                                    data-target="#0">
                                    <Link
                                    to="/threeDigiLotto"
                                    >
                                    Start Playing
                                    </Link>
                                </button>
                                
                            </div>
                            {/*<div className="row justify-content-center">
                                <div className="col-lg-12">
                                    <div className="ani-img">
                                        <img className="cir-1" src="images/coin-1.png" alt="circle"/>
                                        <img className="cir-2" src="images/coin-2.png" alt="circle"/>
                                        <img className="cir-3" src="images/coin-3.png" alt="circle"/>
                                        <img className="cir-4" src="images/coin-4.png" alt="circle"/>
                                    </div>
                                    <div className="row justify-content-center">
                                        <div className="col-lg-6">
                                            <div className="bottom-area text-center">
                                                <img src="images/banner-illu.png" alt="banner-circle"/>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="ani-illu">
                                        <img className="left-1 wow fadeInUp" src="images/banner-left-obj.png" alt=""/>
                                        <img className="right-2 wow fadeInUp" src="images/banner-right-obj.png" alt=""/>
                                    </div>
                                </div>
  </div> */ }
                        </div>
                    </div>
                   {/* <div className="bottom-item">
                        <div className="row">
                            <div className="col-lg-4 col-md-6">
                                <div className="single-item d-flex">
                                    <div className="left-area align-items-center">
                                        <img src="images/banner-bottom-1.png" alt=" "/>
                                    </div>
                                    <div className="right-area">
                                        <h4>PLAY</h4>
                                        <p>A huge collection of Lotto games.</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-4 col-md-6">
                                <div className="single-item d-flex">
                                    <div className="left-area align-items-center">
                                        <img src="images/banner-bottom-2.png" alt=" "/>
                                    </div>
                                    <div className="right-area">
                                        <h4>WIN</h4>
                                        <p>Realtime BNB coins every game</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-4 col-md-6">
                                <div className="single-item d-flex">
                                    <div className="left-area align-items-center">
                                        <img src="images/banner-bottom-3.png" alt=" "/>
                                    </div>
                                    <div className="right-area">
                                        <h4>EARN</h4>
                                        <p>Stake BNB in Games and earn BNB as rewards</p>
                                    </div>
                                </div>
                            </div>
                        </div>
</div>*/}
                </div>
            </div>
        </div>
    </div>

    <section id="available-game-section">
        <div className="overlay pt-120 pb-120">
            <div className="container wow fadeInUp">
                <div className="row justify-content-between">
                    <div className="col-lg-6 col-md-6">
                        <div className="section-header">
                            <h2 className="title">Available Lottery</h2>
                            <p>To play today's Lottery & earn BNB Coin</p>
                        </div>
                    </div>
                    <div className="col-lg-5 col-md-5 d-flex align-items-center justify-content-end justify-cen">
                        <div className="right-area">
                            <Link className="cmn-btn-alt cmn-btn"
                            to="/oneDigiLotto"
                            >
                            TOP GAMES
                            <img
                            src="images/double-right.png" alt=" "/>
                            </Link>
                        </div>
                    </div>
                </div>
                <div className="row contained-area mb-50-none">
                    <div className="col-lg-6 col-sm-6">
                        <img src="images/game-1.png" alt=" "/>
                    </div>
                    <div className="col-lg-6 col-sm-6">
                        <img src="images/game-2.png" alt=" "/>
                    </div>
                    
                    
                </div>
            </div>
        </div>
    </section>

    <section id="level-up-section">
        <div className="overlay pt-120 pb-120">
            <div className="container-fluid">
                <div className="row">
                    <div className="col-lg-6">
                        <div className="left-area d-flex justify-content-lg-center">
                            <img src="images/level-up-left.png" alt=" "/>
                        </div>
                    </div>
                    <div className="col-lg-6 d-flex align-items-center">
                        <div className="container wow fadeInUp">
                            <div className="row">
                                <div className="col-lg-10 align-items-center right-area">
                                    <div className="section-header">
                                        <h2 className="title">STAKE UP! GET REWARDS</h2>
                                        <p>Stake BNB Coin and earn rewards every day</p>
                                    </div>
                                    <div className="row">
                                        <div className="col-lg-12 d-flex justify-content-between">
                                            <div className="single-item">
                                                <img src="images/freespins-icon.png" alt=" "/>
                                                <h6>Stake</h6>
                                            </div>
                                            <div className="single-item">
                                                <img src="images/bonuses-icon.png" alt=" "/>
                                                <h6>Earn</h6>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>

    <section id="todays-winner-section" className="home">
        <div className="overlay pt-120 pb-120">
            <div className="container wow fadeInUp">
                <div className="row">
                    <div className="col-lg-3 col-md-6 col-sm-6 d-grid align-items-center">
                        <div className="section-header">
                            <h2 className="title">Biggest <span>Winners</span></h2>
                            <button type="button" className="cmn-btn-alt cmn-btn" data-toggle="modal"
                            data-target="#signUpModalWin">
                                <Link 
                                to="/games"
                                >
                                Start Playing!
                                </Link>
                            </button>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-6 col-sm-6 d-flex align-items-end jus-end">
                        <div className="mid-area">
                            <img src="images/tropy.png" alt=" "/>
                        </div>
                    </div>
                    <div className="col-lg-6">
                        <div className="title-area">
                            <img src="images/ribbon.png" alt=" "/>
                            <h5>Biggest Winners</h5>
                        </div>
                        <div className="winner-chart">
                            <span className="ribbon">Top Winner</span>
                            <div className="single-item d-flex justify-content-between align-items-center">
                                <div className="left-area d-flex align-items-center">
                                    <img src="images/win-1.png" className="pos" alt=" "/>
                                        <h6>Bessie Cooper</h6>
                                </div>
                                <div className="win-price">
                                    <span>+</span>
                                    <span>56.65994</span>
                                    <span className="cur">BNB</span>
                                    <span><img src="images/double-right-alt.png" alt=" "/></span>
                                </div>
                            </div>
                            <div className="single-item d-flex justify-content-between align-items-center">
                                <div className="left-area d-flex align-items-center">
                                    <img src="images/win-2.png" className="pos" alt=" "/>
                                        <h6>Jerome Bell</h6>
                                </div>
                                <div className="win-price">
                                    <span>+</span>
                                    <span>56.65994</span>
                                    <span className="cur">BNB</span>
                                    <span><img src="images/double-right-alt.png" alt=" "/></span>
                                </div>
                            </div>
                            <div className="single-item d-flex justify-content-between align-items-center">
                                <div className="left-area d-flex align-items-center">
                                    <img src="images/win-3.png" className="pos" alt=" "/>
                                        <h6>Darrell Steward</h6>
                                </div>
                                <div className="win-price">
                                    <span>+</span>
                                    <span>56.65994</span>
                                    <span className="cur">BNB</span>
                                    <span><img src="images/double-right-alt.png" alt=" "/></span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>

    <section id="counter-section">
        <div className="overlay pt-120 pb-120">
            <div className="container">
                <div className="row mb-40-none">
                    <div className="col-lg-3 col-md-6 col-sm-6">
                        <div className="single-item mb-40 text-center">
                            <img src="images/counter-icon-1.png" alt=" "/>
                            <span className="count">$ <span className="counter">133501</span></span>
                            <h6>JACKPOT</h6>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-6 col-sm-6">
                        <div className="single-item text-center">
                            <img src="images/counter-icon-2.png" alt=" "/>
                            <span className="count">$ <span className="counter">81237619</span></span>
                            <h6>TOTAL PAID</h6>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-6 col-sm-6">
                        <div className="single-item text-center">
                            <img src="images/counter-icon-3.png" alt=" "/>
                            <span className="count"><span className="counter">100</span>%</span>
                            <h6>SECURE</h6>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-6 col-sm-6">
                        <div className="single-item mb-40 text-center">
                            <img src="images/counter-icon-4.png" alt=" "/>
                            <span className="count"><span className="counter">24</span>/<span className="counter">7</span></span>
                            <h6>SUPPORT</h6>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>

    <section id="latest-activites-section">
        <div className="ani-div">
            <img className="obj-1" src="images/star.png" alt=" "/>
            <img className="obj-2" src="images/star.png" alt=" "/>
            <img className="obj-3" src="images/star-2.png" alt=" "/>
        </div>
        <div className="overlay pt-120">
            <div className="container wow fadeInUp">
                <div className="row justify-content-between">
                    <div className="col-lg-6 col-md-6">
                        <div className="section-header">
                            <h2 className="title">Latest Activites</h2>
                            <p>Each time you play games and win Prizes instantly</p>
                        </div>
                    </div>
                    <div className="col-lg-5 col-md-5 d-flex align-items-center justify-content-end justify-cen">
                        <ul className="nav nav-tabs" id="myTab" role="tablist">
                            <li className="nav-item">
                                <a className="nav-link active" id="bets-tab" data-toggle="tab" href="#bets" role="tab"
                                    aria-controls="bets" aria-selected="true">Latest Bets</a>
                            </li>
                            <li className="nav-item">
                                <a className="nav-link" id="contest-tab" data-toggle="tab" href="#contest" role="tab"
                                    aria-controls="contest" aria-selected="false">Contest</a>
                            </li>
                        </ul>
                    </div>
                </div>
                <div className="row">
                    <div className="col-lg-12">
                        <div className="tab-content" id="myTabContent">
                            <div className="tab-pane fade show active" id="bets" role="tabpanel" aria-labelledby="bets-tab">
                                <div className="table-responsive">
                                    <table className="table">
                                        <thead>
                                            <tr>
                                                <th className="text-start" scope="col">Game</th>
                                                <th scope="col">Player</th>
                                                <th scope="col">Bet ID</th>
                                                <th scope="col">Payout</th>
                                                <th scope="col">Profit</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <th scope="row">
                                                        <img src="images/game-icon-1.png" alt=" "/>3D-Lotto
                                                        </th>
                                                <td>Bessie Cooper</td>
                                                <td>30320267096</td>
                                                <td>0.70X</td>
                                                <td><img src="images/table-icon-1.png" alt=" "/>+ 0.00312150</td>
                                            </tr>
                                            <tr>
                                                <th scope="row"><img src="images/game-icon-2.png" alt=" "/>4D-Pool</th>
                                                <td>Eleanor Pena</td>
                                                <td>30320267096</td>
                                                <td>2.21X</td>
                                                <td><img src="images/table-icon-2.png" alt=" "/>+ 0.00312150</td>
                                            </tr>
                                            <tr>
                                                <th scope="row"><img src="images/game-icon-3.png" alt=" "/>TOTO</th>
                                                <td>Cody Fisher</td>
                                                <td>30320267096</td>
                                                <td>3.01X</td>
                                                <td><img src="images/table-icon-3.png" alt=" "/>+ 0.00312150</td>
                                            </tr>
                                            <tr>
                                                <th scope="row"><img src="images/game-icon-4.png" alt=" "/>MINI Roulette</th>
                                                <td>Arlene McCoy</td>
                                                <td>30320267096</td>
                                                <td>1.11X</td>
                                                <td><img src="images/table-icon-4.png" alt=" "/>- 0.00312150</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                            <div className="tab-pane fade" id="contest" role="tabpanel" aria-labelledby="contest-tab">
                                <div className="table-responsive">
                                    <table className="table">
                                        <thead>
                                            <tr>
                                                <th className="text-start" scope="col">Game</th>
                                                <th scope="col">Player</th>
                                                <th scope="col">Bet ID</th>
                                                <th scope="col">Payout</th>
                                                <th scope="col">Profit</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <th scope="row"><img src="images/game-icon-1.png" alt=" "/>3D-Lotto</th>
                                                <td>Bessie Cooper</td>
                                                <td>30320267096</td>
                                                <td>0.70X</td>
                                                <td><img src="images/table-icon-1.png" alt=" "/>+ 0.00312150</td>
                                            </tr>
                                            <tr>
                                                <th scope="row"><img src="images/game-icon-2.png" alt=" "/>4D-Pool</th>
                                                <td>Eleanor Pena</td>
                                                <td>30320267096</td>
                                                <td>2.21X</td>
                                                <td><img src="images/table-icon-2.png" alt=" "/>+ 0.00312150</td>
                                            </tr>
                                            <tr>
                                                <th scope="row"><img src="images/game-icon-3.png" alt=" "/>TOTO</th>
                                                <td>Cody Fisher</td>
                                                <td>30320267096</td>
                                                <td>3.01X</td>
                                                <td><img src="images/table-icon-3.png" alt=" "/>+ 0.00312150</td>
                                            </tr>
                                            <tr>
                                                <th scope="row"><img src="images/game-icon-4.png" alt=" "/>MINI Roulette</th>
                                                <td>Arlene McCoy</td>
                                                <td>30320267096</td>
                                                <td>1.11X</td>
                                                <td><img src="images/table-icon-4.png" alt=" "/>- 0.00312150</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>

    <section id="faq-section">
        <div className="overlay pt-120 pb-120">
            <div className="container">
                <div className="row justify-content-center text-center">
                    <div className="col-lg-8">
                        <div className="section-header">
                            <h2 className="title">Our most common questions</h2>
                            <p>Do you have a question about 3DLotto.org? Please contact us! We would love
                                to answer your questions.</p>
                        </div>
                    </div>
                </div>
                <div className="row d-flex justify-content-center">
                    <div className="col-lg-5 d-flex align-items-center cus-mb">
                        <div className="left-area">
                            <span>
                                <img src="images/faq-left.png" alt="h"/>
                            </span>
                        </div>
                    </div>
                    <div className="col-lg-7 wow fadeInUp">
                        <div id="accordion-one">
                            <div className="card">
                                <div className="card-header" id="headingOne">
                                    <h6 className="mb-0">
                                        <button className="btn btn-link" data-toggle="collapse" data-target="#collapseOne"
                                            aria-expanded="true" aria-controls="collapseOne">
                                            Can I earn money playing on 3DLotto?
                                        </button>
                                    </h6>
                                </div>
                                <div id="collapseOne" className="collapse show" aria-labelledby="headingOne"
                                    data-parent="#accordion-one">
                                    <div className="card-body">
                                        <p>Duis sapien justo, posuere interdum mauris eu, tincidunt placerat ante.
                                            Curabitur aliquet leo ipsum, at maximus dolor fringilla vel. Aliquam
                                            sagittis elementum volutpat. Vestibulum vel arcu nisl.</p>
                                    </div>
                                </div>
                            </div>
                            <div className="card">
                                <div className="card-header" id="headingTwo">
                                    <h6 className="mb-0">
                                        <button className="btn btn-link collapsed" data-toggle="collapse"
                                            data-target="#collapseTwo" aria-expanded="false"
                                            aria-controls="collapseTwo">
                                            Are there any benefits other than money?
                                        </button>
                                    </h6>
                                </div>
                                <div id="collapseTwo" className="collapse" aria-labelledby="headingTwo"
                                    data-parent="#accordion-one">
                                    <div className="card-body">
                                        <p>Duis sapien justo, posuere interdum mauris eu, tincidunt placerat ante.
                                            Curabitur aliquet leo ipsum, at maximus dolor fringilla vel. Aliquam
                                            sagittis elementum volutpat. Vestibulum vel arcu nisl.</p>
                                    </div>
                                </div>
                            </div>
                            <div className="card">
                                <div className="card-header" id="headingThree">
                                    <h6 className="mb-0">
                                        <button className="btn btn-link collapsed" data-toggle="collapse"
                                            data-target="#collapseThree" aria-expanded="false"
                                            aria-controls="collapseThree">
                                            What is a Lotto, and how do I play?
                                        </button>
                                    </h6>
                                </div>
                                <div id="collapseThree" className="collapse" aria-labelledby="headingThree"
                                    data-parent="#accordion-one">
                                    <div className="card-body">
                                        <p>Duis sapien justo, posuere interdum mauris eu, tincidunt placerat ante.
                                            Curabitur aliquet leo ipsum, at maximus dolor fringilla vel. Aliquam
                                            sagittis elementum volutpat. Vestibulum vel arcu nisl.</p>
                                    </div>
                                </div>
                            </div>
                            <div className="card">
                                <div className="card-header" id="headingFour">
                                    <h6 className="mb-0">
                                        <button className="btn btn-link collapsed" data-toggle="collapse"
                                            data-target="#collapseFour" aria-expanded="false"
                                            aria-controls="collapseFour">
                                            Can I earn other than playing games?
                                        </button>
                                    </h6>
                                </div>
                                <div id="collapseFour" className="collapse" aria-labelledby="headingFour"
                                    data-parent="#accordion-one">
                                    <div className="card-body">
                                        <p>Duis sapien justo, posuere interdum mauris eu, tincidunt placerat ante.
                                            Curabitur aliquet leo ipsum, at maximus dolor fringilla vel. Aliquam
                                            sagittis elementum volutpat. Vestibulum vel arcu nisl.</p>
                                    </div>
                                </div>
                            </div>
                            <div className="card">
                                <div className="card-header" id="headingFive">
                                    <h6 className="mb-0">
                                        <button className="btn btn-link collapsed" data-toggle="collapse"
                                            data-target="#collapseFive" aria-expanded="false"
                                            aria-controls="collapseFive">
                                            Who do I compete against in the Lotto?
                                        </button>
                                    </h6>
                                </div>
                                <div id="collapseFive" className="collapse" aria-labelledby="headingFive"
                                    data-parent="#accordion-one">
                                    <div className="card-body">
                                        <p>Duis sapien justo, posuere interdum mauris eu, tincidunt placerat ante.
                                            Curabitur aliquet leo ipsum, at maximus dolor fringilla vel. Aliquam
                                            sagittis elementum volutpat. Vestibulum vel arcu nisl.</p>
                                    </div>
                                </div>
                            </div>
                            <div className="card">
                                <div className="card-header" id="headingSix">
                                    <h6 className="mb-0">
                                        <button className="btn btn-link collapsed" data-toggle="collapse"
                                            data-target="#collapseSix" aria-expanded="false"
                                            aria-controls="collapseSix">
                                            How does 3DLotto is Decentralized?
                                        </button>
                                    </h6>
                                </div>
                                <div id="collapseSix" className="collapse" aria-labelledby="headingSix"
                                    data-parent="#accordion-one">
                                    <div className="card-body">
                                        <p>Duis sapien justo, posuere interdum mauris eu, tincidunt placerat ante.
                                            Curabitur aliquet leo ipsum, at maximus dolor fringilla vel. Aliquam
                                            sagittis elementum volutpat. Vestibulum vel arcu nisl.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    </div>
  );
}

export default Home