import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import { utils } from 'ethers'
import lottoDefiAbi from './contracts/lottoDefi'
import { Contract } from '@ethersproject/contracts'
import { useEthers, useCall, useContractFunction } from '@usedapp/core'
import { parseUnits } from '@ethersproject/units'


function DefiApp() {
    

    const { account, library } = useEthers();
    const contractAddress = '0xD77e051E5C847bf4Ae9A711712c904a0867d388d';
    const contractInterface = new utils.Interface(lottoDefiAbi);
    const _contract = new Contract(contractAddress, contractInterface);
    const signer = library.getSigner();


    const StakeFunction = () => {
        const [_value, setValue] = useState('');
        const MIN = '0.5';
        const MAX = '5.0';
        const { value: totalStaked } = useCall({
            contract: _contract,
            method: 'totalStaked',
            args:[]
        }) ?? {}
    
        const TotalStaked = totalStaked / 10 ** 18;
        const FinalResult = String(TotalStaked)
    
        const {state, send} = useContractFunction(_contract, 'stake', {
            transactionName: 'Stake',
            signer: signer
        })
        const { status } = state
        const stakeBNB = () => {
            send({value: parseUnits(_value, 'ether')})
        }

        return (
            <div className="row d-flex justify-content-center wi-100 pb-360">
                <div className="col-lg-10 text-center">
                    <div className="bottom-area">
                        <h3>Stake BNB</h3>
                        <h5 className='mt-2 mb-2'>Total Staked in Defi = {FinalResult} - BNB</h5>
                        <div className="prize-area">
                            <h5>{_value} - BNB</h5>
                            <div className="prize d-flex justify-content-center">
                            
                            <div className="slidecontainer">
                                <input type="range" step='0.10' min={MIN} max={MAX} value={_value} onChange={(e) => setValue(e.target.value)} className="slider" id="myRange" />
                            </div>
                            </div>
                            <h6>0.50 BNB min / 5.00 BNB max</h6>
                            <p>Status : {status}</p>
                        </div>
                            <button onClick={() => stakeBNB()} type="button" className="cmn-btn mb-4">
                            Stake
                            </button>
                    </div>
                </div>
            </div>
        )
    }

    const UnStakeFunction = () => {
        
        const { value: userStaked } = useCall({
            contract: _contract,
            method: 'staked',
            args:[account ?? '']
        }) ?? {}
    
        const TotalStaked = userStaked / 10 ** 18;
        const FinalResult = String(TotalStaked)

        const { value: userStakedDay } = useCall({
            contract: _contract,
            method: 'stakedDay',
            args:[account ?? '']
        }) ?? {}
    
        const StakedDay = userStakedDay;
        const FinalDayResult = String(StakedDay)

        
    
        const {state, send} = useContractFunction(_contract, 'unStake', {
            transactionName: 'UnStake',
            signer: signer
        })
        const { status } = state
        const unStakeBNB = () => {
            send()
        }


        return (
            <div className="row d-flex justify-content-center wi-100 pb-360">
                <div className="col-lg-10 text-center">
                    <div className="bottom-area">
                        <h3>Un Stake BNB</h3>
                        <h5 className='mt-2 mb-2'>You Staked in Defi = {FinalResult} - BNB</h5>
                        <h5 className='mt-2 mb-2'>You Staked On = Day - {FinalDayResult}</h5>
                        <div className="prize-area">
                            <div className="prize d-flex justify-content-center">
                            <h5>Status : {status}</h5>
                            </div>
                            <p>UnStake your fund after 7 Days of Staking period</p>
                        </div>
                        
                            <button onClick={() => unStakeBNB()} type="button" className="cmn-btn mb-4">
                            UnStake
                            </button>
                    </div>
                </div>
            </div>
        )
    }

    const ClaimRewardFunction = () => {
        const { value: userStakedDay } = useCall({
            contract: _contract,
            method: 'stakedDay',
            args:[account ?? '']
        }) ?? {}
    
        const StakedDay = userStakedDay;
        const FinalDayResult = String(StakedDay)
    
        const {state, send} = useContractFunction(_contract, 'claimAllProfits', {
            transactionName: 'Claim Rewards',
            signer: signer
        })
        const { status } = state
        const claimRewards = () => {
            send()
        }

        return (
            <div className="row d-flex justify-content-center wi-100 pb-360">
                <div className="col-lg-10 text-center">
                    <div className="bottom-area">
                        <h3>Claim Rewards</h3>
                        <h5 className='mt-2 mb-2'>You Staked On = Day - {FinalDayResult}</h5>
                        <div className="prize-area">
                            <div className="prize d-flex justify-content-center">
                            <h5>Status : {status}</h5>
                            </div>
                            <p>Claim your Rewards after 7 Days of Staked Day</p>
                            
                        </div>
                            <button onClick={() => claimRewards()} type="button" className="cmn-btn mb-4">
                            Claim Rewards
                            </button>
                    </div>
                </div>
            </div>
        )
    }
    

  return (
    <div>
      <section id="banner-section" className="inner-banner games tournaments tourn-details">
        <div className="banner-content d-flex align-items-center">
            <img src="images/tour-right-illu.png" className="banner-right-illu" alt="  "/>
            <div className="container">
                <img src="images/tour-left-banner.png" className="banner-left" alt="  "/>
                <img src="images/tour-right-banner.png" className="banner-right" alt="  "/>

                <div className="row d-flex justify-content-start">
                    <div className="col-lg-12">
                        <div className="text-area">
                            <h1>Lotto Defi</h1>
                            <div className="breadcrumb-area">
                                <nav aria-label="breadcrumb">
                                    <ol className="breadcrumb d-flex">
                                        <li className="breadcrumb-item"><Link
                                        to="/"
                                        >
                                    Home
                                    </Link></li>
                                        <li className="breadcrumb-item active" aria-current="page">Defi App</li>
                                    </ol>
                                </nav>
                            </div>
                        </div>
                    </div>
                    <div className="prize row d-flex justify-content-center wi-100 mb-4">
                    <h5>Contract Address : <a href='https://testnet.bscscan.com/address/0xc67219fc367eaf473f6616bd0dab7fcc006ee6ca#code' > { contractAddress } </a></h5>
                    </div>
                    
                    <StakeFunction />
                    <UnStakeFunction />
                    <ClaimRewardFunction />
                    
                </div>
            </div>
        </div>
    </section>


    <section id="feature-section" className="game-2 tourn-details">
        <div className="overlay pt-120 pb-120">
            <div className="container">
                <div className="row">
                </div>
            </div>
        </div>
    </section>
    </div>
  )
}

export default DefiApp